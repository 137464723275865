import { h, Component, Fragment, createRef } from "preact"
import { fetchAPI } from "../../../libs/api"
import { Dialog } from "../../dialog"

const _ = require('lodash')
import "./participant.scss"

export class T100SeasonParticipant extends Component {
	deleteConfirmDialogRef = createRef()
	errorDialogRef = createRef()

	state = {
		participant: {},
		originalParticipant: {},
		contractTiers: [],
		loading: true,
		isInputError: false,
		isChangesToPersist: false,
	}

	async refreshParticipant() {
		return fetchAPI(`/api/t100/seasons/${this.props.season_id}/participants/${this.props.id}`, { headers: { 'Content-Type': 'application/json' } })
			.then(response => response.json())
			.then(data => this.setState({ originalParticipant: data.participant, participant: data.participant, loading: false }))
			.catch(err => this.setState({ error: err }))
	}

	async fetchContractTiers() {
		return fetchAPI("/api/t100/contract-tiers?is_active=1")
			.then(response => response.json())
			.then(data => this.setState({ contractTiers: data.contractTiers }))
			.catch(err => this.setState({ error: err }))
	}

	componentDidMount() {
		this.refreshParticipant()
		this.fetchContractTiers()
	}

	changeHandler(event, data) {
		const { participant, originalParticipant } = this.state
		const field = event?.target?.dataset?.attr || data.props['data-attr']
		let value = typeof event === "number" ? event : event?.target?.value
		if (field === "order") value = parseInt(value)
		const isInputError = event?.target ? !event.target.checkValidity() : false

		let updatedParticipant = { ...participant }
		updatedParticipant[field] = (value === "" || value === undefined) ? null : value
		const isChangesToPersist = !_.isEqual(originalParticipant, updatedParticipant)

		this.setState({
			isEmptyField: value === (null || ""),
			isInputError,
			isChangesToPersist,
			participant: updatedParticipant,
		})
	}

	updateParticipant() {
		const { participant } = this.state

		fetchAPI(`/api/t100/seasons/${this.props.season_id}/participants/${this.props.id}`, {
			method: "put",
			body: JSON.stringify({ ...participant }),
			headers: { 'Content-Type': 'application/json' }
		})
			.then(response => response.json())
			.then(() => {
				this.setState({ originalParticipant: participant, isChangesToPersist: false })
				location = `/t100/season/${this.props.season_id}/participants/${this.props.id}`
			})
			.catch(error => {
				this.setState({ errorMessage: error.message })
				this.errorDialogRef.current.show()
			})
	}

	handleError(error) {
		this.setState({ errorMessage: error.message })
		this.errorDialogRef.current.show()
	}

	deleteParticipant() {
		fetchAPI(`/api/t100/seasons/${this.props.season_id}/remove_participant/${this.props.id}`, {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
		})
			.then(() => {
				location = `/t100/season/${this.props.season_id}#participants`
			})
	}

	openDeleteDialog() {
		this.deleteConfirmDialogRef.current.show()
	}

	render(props, state) {
		const {
			participant,
			originalParticipant,
			loading,
			error,
			errorMessage,
			isEmptyField,
			isInputError,
			isChangesToPersist
		} = this.state

		if (error) return <p>{ error.message }</p>
		return !loading && (
			<div class="season-participant-info">
				<h2 class="headline">{ originalParticipant?.name }</h2>

				<div class="card">
					<table>
						<tr>
							<th>Contract Tier</th>
							<td>
								<div class='select'>
									<select class='form-control form-control-sm' data-attr="contract_tier_id" onChange={ this.changeHandler.bind(this) }>
										{ state.contractTiers.map(x => <option selected={x.id === participant.contract_tier_id} value={ x.id } >{ x.name }</option>) }
									</select>
								</div>
							</td>
						</tr>
					</table>
					<div class="pt-3">
						<button class="btn btn-sm btn-primary"
							onClick={ this.updateParticipant.bind(this) }
							disabled={ (!isChangesToPersist || isEmptyField || isInputError) }>
							Save Changes
						</button>

						<Fragment>
							<button class="btn btn-sm btn-danger ml-3" onClick={ this.openDeleteDialog.bind(this) }>
								Remove Participant
							</button>

							<Dialog title="Delete season?" ref={ this.deleteConfirmDialogRef } yesText="Remove" onYes={ this.deleteParticipant.bind(this) }>
								<p>Are you sure you want to remove this participant?</p>
							</Dialog>
						</Fragment>
					</div>

					<Dialog nofooter={ true } title="Error!" ref={ this.errorDialogRef }>
						<p>{ errorMessage }</p>
					</Dialog>
				</div>
			</div>
		)
	}
}

