import { h, Component, Fragment } from "preact";
import moment from 'moment';


export let categories_map = ["WC.FD", "C.FD", "FD", "C.HD", "HD", "WC.HD"]

export function dateToString(d) {
	return moment.utc(d).format("D MMM")
}

export function getSeconds(t) {
	if (!t) {
		return -1
	}
 
	let parts = t.split(":")
	let seconds = 0
 
	if (parts.length === 2) {
		seconds = parseInt(parts[0]) * 60 + parseInt(parts[1])
	} else if (parts.length === 3) {
		seconds =
			parseInt(parts[0]) * 60 * 60 +
			parseInt(parts[1]) * 60 +
			parseInt(parts[2])
	} else {
		return -1;
	}
 
	if (t.includes(".")) {
		let milliseconds = parseInt(t.split(".")[1])
		seconds += Math.ceil(milliseconds / 1000)
	}
 
	return seconds
 }

export function secondsToTime(secs, forcehours = false) {
	if (secs <= 0) {
		return "--:--";
	}
	var seconds = secs % 60;
	var minutes = ((secs - seconds) / 60) % 60
	var hours = (secs - seconds - minutes*60) / 3600;
	if (hours > 0 || forcehours) {
		return (hours >= 10 ? "" : "0") + hours + ":" + (minutes >= 10 ? "" : "0") + minutes + ":" + (seconds >= 10 ? "" : "0") + seconds;
	} 
	else if (minutes > 0) {
		return (minutes >= 10 ? "" : "") + minutes + ":" + (seconds >= 10 ? "" : "0") + seconds;
	}
	else {
		return "00:" + (seconds >= 10 ? "" : "0") + seconds;
	}
}

export function canonical(s) {
	s = s.toLowerCase()
	s = s.replace(/[àáâãäåã]/g, 'a')
	s = s.replace(/[èéêë]/g, 'e')
	s = s.replace(/[ìíîï]/g, 'i')
	s = s.replace(/[òóôõöø]/g, 'o')
	s = s.replace(/[ùúûü]/g, 'u')
	s = s.replace(/[ýÿ]/g, 'y')
	s = s.replace(/[š]/g, 's')
	s = s.replace(/[ç]/g, 'c')
	s = s.replace(/[ñ]/g, 'n')
	s = s.replace(/[ž]/g, 'z')
	s = s.replace(/[æ]/g, 'ae')
	s = s.replace(/[ ]/g, '-')
	s = s.replace(/[.'?,]/g, '')

	return s
}

export function ordinal_suffix_of(i) 
{
	var j = i % 10, k = i % 100;
	if (j == 1 && k != 11) {
		return i + "st";
	}
	if (j == 2 && k != 12) {
		return i + "nd";
	}
	if (j == 3 && k != 13) {
		return i + "rd";
	}
	return i + "th";
}

export class AthletePic extends Component {

	render(props)
	{
		//if(props.src) 
		return (<a href={props.link && props.link[0] == '/' ? props.link : '/athlete/' + props.link} class='athlete-pic-group'><img class={'athlete-pic ' + (props.class || "")} src={props.src} sizes={props.sizes} srcset={props.srcset} alt={props.link}/><div class={(props.src ? "" : " nopic")}></div></a>)
		return (
			<div class='athlete-pic nopic'></div>
		)
	}
}

const smoothing = 0.2

const line = (pointA, pointB) => {
	const lengthX = pointB[0] - pointA[0]
	const lengthY = pointB[1] - pointA[1]
	return {
		length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
		angle: Math.atan2(lengthY, lengthX)
	}
}
const controlPoint = (current, previous, next, reverse) => {
	const p = previous || current
	const n = next || current
	
	const o = line(p, n)

	const angle = o.angle + (reverse ? Math.PI : 0)
	const length = o.length * smoothing

	const x = current[0] + Math.cos(angle) * length
	const y = current[1] + Math.sin(angle) * length
	return [x, y]
}

const bezierCommand = (point, i, a) => {
	const cps = controlPoint(a[i - 1], a[i - 2], point)
	const cpe = controlPoint(point, a[i - 1], a[i + 1], true)
	return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}`
}

const lineCommand = (point, i, a) => {
	const cps = controlPoint(a[i - 1], a[i - 2], point)
	const cpe = controlPoint(point, a[i - 1], a[i + 1], true)
	return `L ${point[0]},${point[1]}`
}

const svgPath = (points, command) => {
	const d = points.reduce((acc, point, i, a) => i === 0 ? `M ${point[0]},${point[1]}` : `${acc} ${command(point, i, a)}` , '')
	return d
}



export class GraphRankings extends Component {
	render(props) {
		let rankings = props.rankings

		let swimPoints = [], bikePoints = [], runPoints = [], overallPoints = [], labels = [], labels_y = []

		let max_rank = rankings.map(el => Math.max(el.rank, Math.max(el.swim_rank, Math.max(el.bike_rank, el.run_rank)))).reduce((m,c) => Math.max(m,c), 0)
		let min_rank = rankings.map(el => Math.min(el.rank, Math.min(el.swim_rank, Math.min(el.bike_rank, el.run_rank)))).reduce((m,c) => Math.min(m,c), 10000)

		min_rank = Math.max(1, min_rank - 5)
		max_rank = max_rank + 5

		let step = parseInt((max_rank - min_rank) / 4)
		for (let y = min_rank; y < max_rank; y += step) {
			labels_y.push({ name: y, y: 10 + 170 * ((y - min_rank) / (max_rank - min_rank))})
		}		

		for (let x = 0; x < rankings.length; x++) {
			let fx = 50 + (620 * x / (rankings.length - 1))
			let fy = (rankings[x].rank - min_rank) / (max_rank - min_rank)
			overallPoints.push([fx, 10 + (170 * fy)])

			fy = (rankings[x].swim_rank - min_rank) / (max_rank - min_rank)
			swimPoints.push([fx, 10 + (170 * fy)])

			fy = (rankings[x].bike_rank - min_rank) / (max_rank - min_rank)
			bikePoints.push([fx, 10 + (170 * fy)])

			fy = (rankings[x].run_rank - min_rank) / (max_rank - min_rank)
			runPoints.push([fx, 10 + (170 * fy)])

			labels.push({name: rankings[x].season, x: fx, sx: 620 / (rankings.length - 1), data: rankings[x] })
		}

		return (
			<div class='graph-container'>
				<div class='graph-resizer'>
					<svg width="100%" height="100%" viewBox="0 0 700 210" preserveAspectRatio="none" style='user-select: none; overflow: visible'>
						<defs>
							<filter id="shadow">
								<feDropShadow dx="0" dy="0" stdDeviation="4" flood-color="black" flood-opacity="0.5"/>
							</filter>
						</defs>
						<g style='pointer-events: none;'></g>
						<g style='pointer-events: none;' class='grid'>
							{ labels_y.map(p => (<text x="35" y={p.y}>#{p.name}</text>)) }
							{ labels_y.map(p => (<line x1="40" x2="690" y1={p.y} y2={p.y} style='stroke: #888; stroke-width: 0.5px;'/>)) }
							{ labels.map(p => (<line x1={p.x} x2={p.x} y1="5" y2="185" style='stroke: #888; stroke-width: 0.5px;'/>)) }
							<g class='labels'>
								{ labels.map(p => (<text x={p.x} y={200}>{ p.name }</text>)) }
							</g>
						</g>
						<g class='swim'>
							<path d={ svgPath(swimPoints, bezierCommand) }/>
							{ swimPoints.map(p => (<circle cx={p[0]} cy={p[1]} r="6"/>)) }
						</g>

						<g class='bike'>
							<path d={ svgPath(bikePoints, bezierCommand) }/>
							{ bikePoints.map(p => (<circle cx={p[0]} cy={p[1]} r="6"/>)) }
						</g>

						<g class='run'>
							<path d={ svgPath(runPoints, bezierCommand) }/>
							{ runPoints.map(p => (<circle cx={p[0]} cy={p[1]} r="6"/>)) }
						</g>

						<g class='overall'>
							<path d={ svgPath(overallPoints, bezierCommand) }/>
							{ overallPoints.map(p => (<circle cx={p[0]} cy={p[1]} r="6"/>)) }
						</g>
						<g class='mouse'>
							{ labels.map(p => {
								let width = 180
								let top = -95
								let posx = p.x - width * 0.5 //p.x > (700 - 20 - width) ? p.x - (width + 20) : p.x + 20;
								if(posx < 10) posx = 10
								if(posx + width > 700 - 10) posx = 700 - 10 - width
								return (
									<g class='hover'>
										<rect x={p.x - p.sx * 0.5} width={ p.sx } y={ 0 } height="210" style="fill: transparent;"/>
										<g class='popup' style='pointer-events: none; filter: url(#shadow);'>
											<rect x={posx} y={top - 5} width={width} height={100}/>
											<text class='year' x={posx + width * 0.5 } y={ top + 10 }>{p.data.season}</text>

											<text class='overall' x={posx + 10} y={ top + 30 }>Overall</text>
											<text class='points' x={ posx + width * 0.5 } y={ top + 30 }>{p.data.points} pts</text>
											<text class='rank' x={ posx + width - 10 } y={ top + 30 }>#{p.data.rank}</text>
											
											<text class='swim' x={posx + 10} y={ top + 55 }>Swim</text>
											<text class='points' x={ posx + width * 0.5 } y={ top + 55 }>{p.data.swim_points} pts</text>
											<text class='rank' x={ posx + width - 10 } y={ top + 55 }>#{p.data.swim_rank}</text>

											<text class='bike' x={posx + 10} y={ top + 70 }>Bike</text>
											<text class='points' x={ posx + width * 0.5 } y={ top + 70 }>{p.data.bike_points} pts</text>
											<text class='rank' x={ posx + width - 10 } y={ top + 70 }>#{p.data.bike_rank}</text>

											<text class='run' x={posx + 10} y={ top + 85 }>Run</text>
											<text class='points' x={ posx + width * 0.5 } y={ top + 85 }>{p.data.run_points} pts</text>
											<text class='rank' x={ posx + width - 10 } y={ top + 85 }>#{p.data.run_rank}</text>

										</g>
									</g>
								)
							}) }
						</g>
					</svg>
				</div>
			</div>
		)
	}
}
